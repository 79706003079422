import { useState, useEffect } from "react"
import { Button, Box, TextField, Select, MenuItem, Pagination } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../../../utils/ContextProvider.jsx/index.jsx"
import axios from "axios"
import DashboardHeader from "../../../../components/Dashboard/Header/index.jsx"
import Modal from "../../../../components/Modal/index.jsx"

export default function AllPartnerMappingsPage () {

    var [ unprocessedMappings, setUnprocessedMappings ] = useState()
    
    var [ allShoes, setAllShoes ] = useState([])

    var [allPartners, setAllPartners] = useState([])

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading } = useAppContext()
    
    var [ showAddMapping, setShowAddMapping ] = useState(false)

    var [newPartnerDealInfo, setNewPartnerDealInfo] = useState({
        partner: '',
        urlKey: '',
        price: 0,
        specialPrice: null,
        sku: '',
        processed: false,
        validDeal: true
    })

    var navigate = useNavigate()

    var buttons = [
        {
            text: 'Add new mapping',
            function: () => {
                setShowAddMapping(true)
            }
        }
    ]

    var [filter, setFilter] = useState(''); // New state for filter

    var [selectedPartner, setSelectedPartner] = useState(''); // New state for selected partner

    const [currentPage, setCurrentPage] = useState(1); // New state for current page
    const itemsPerPage = 20; // Number of items per page

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [partnerDealsRes, shoesRes, partnersRes] = await Promise.all([
                    axios({
                        url: `${requestUrl}/api/partnerDeals?status=unprocessed`,
                        method: "GET",
                        withCredentials: true
                    }),
                    axios({
                        url: `${requestUrl}/api/shoes/`,
                        method: "GET",
                        withCredentials: true
                    }),
                    axios({
                        url: `${requestUrl}/api/partners/`,
                        method: "GET",
                        withCredentials: true
                    })
                ]);
    
                // Update state with data from both API calls
                setUnprocessedMappings(partnerDealsRes.data);
                setAllShoes(shoesRes.data.allShoes);
                setAllPartners(partnersRes.data); // Set all partners
            } catch (err) {
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }));
            }
        };
    
        fetchData();
    }, [requestUrl])

    const handleClick = (evt, mapping) => {
        navigate(`/admin/partners/mappings/${mapping._id}`, {state: {mapping: mapping, unprocessedMappings: unprocessedMappings, allShoes: allShoes }})
    }

    const handleOpenModal = () => setShowAddMapping(true);
    const handleCloseModal = () => setShowAddMapping(false);

    const modalContent = 
        <div>
            <TextField
                select
                label="Partner"
                value={newPartnerDealInfo.partner}
                onChange={(e) => setNewPartnerDealInfo({ ...newPartnerDealInfo, partner: e.target.value })}
                fullWidth
                margin="normal"
            >
                {allPartners.map((partner) => (
                    <option key={partner.id} value={partner.name}>
                        {partner.name}
                    </option>
                ))}
            </TextField>
            <TextField
                label="URL Key"
                value={newPartnerDealInfo.urlKey}
                onChange={(e) => setNewPartnerDealInfo({ ...newPartnerDealInfo, urlKey: e.target.value })}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Price"
                type="number"
                value={newPartnerDealInfo.price}
                onChange={(e) => setNewPartnerDealInfo({ ...newPartnerDealInfo, price: e.target.value })}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Special Price"
                type="number"
                value={newPartnerDealInfo.specialPrice}
                onChange={(e) => setNewPartnerDealInfo({ ...newPartnerDealInfo, specialPrice: e.target.value })}
                fullWidth
                margin="normal"
            />
            <TextField
                label="SKU"
                value={newPartnerDealInfo.sku}
                onChange={(e) => setNewPartnerDealInfo({ ...newPartnerDealInfo, sku: e.target.value })}
                fullWidth
                margin="normal"
            />
        </div>
    

    const handleAddMapping = () => {
        setLoading(true)
        axios({
            url: `${requestUrl}/api/partnerDeals`,
            method: "POST",
            data: newPartnerDealInfo,
            withCredentials: true
        }).then((res) => {
            setLoading(false)
            setShowAddMapping(false)
        }).catch((err) => {
            setLoading(false)
        })
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <DashboardHeader
                heading={`Shoe deals to map to shoes`}
                subHeading={`Click on each item to view a new deal for a shoe. Map the deal to a shoe and shoe colorway for it to show in Fittir.`}
                buttons={buttons}
            />
            <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
                <div className="container-md">
                    <div className="row">
                        <div className="col-auto">
                            <Select
                                label="Partner"
                                    value={selectedPartner}
                                    onChange={(e) => setSelectedPartner(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    displayEmpty
                                >
                                <MenuItem value="">
                                    <em>All Partners</em>
                                </MenuItem>
                                {allPartners.map((partner) => (
                                    <MenuItem key={partner.id} value={partner.name}>
                                        {partner.name}
                                    </MenuItem>
                                ))}
                    </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>{unprocessedMappings?.length} total unmapped deals</p>
                        </div>
                    </div>
                    {unprocessedMappings
                        ?.filter(mapping => selectedPartner === '' || mapping?.partner?.name === selectedPartner)
                        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) // Pagination logic
                        .map((mapping) => { 
                            return (
                                <div className="row my-3" key={mapping._id}>
                                    <div className="col py-3 px-4" style={{backgroundColor: 'white'}}>
                                        <div className="row">
                                            <div className="col-2">
                                                <img className="img img-fluid" src={`https://fittir-assets.s3.ap-southeast-2.amazonaws.com/partners/${mapping?.partner?.slug}.svg`} alt="" />
                                            </div>
                                            <div className="col-10">
                                                <div className="row">
                                                    <div className="col">
                                                        <h2>{mapping?.urlKey} ({mapping?.price})</h2>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between">
                                                    <div className="col-8" style={{wordBreak: 'break-all'}}>
                                                        <p className="my-0">{mapping?.sku}</p>
                                                    </div>
                                                    <div className="col-auto">
                                                        <Button onClick={(evt) => handleClick(evt, mapping)}>Process Mapping</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    <Pagination
                        count={Math.ceil(unprocessedMappings?.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            </div>

            <Modal 
                heading="Add new mapping"
                openState={showAddMapping}
                setOpenState={setShowAddMapping}
                content={modalContent}
                action={handleAddMapping}
                />
        </>
    )
}
